import { useField } from "formik";
import React from "react";

function TextArea({ name, placeholder, wrapperClasses }) {
  const [field, meta] = useField(name);
  return (
    <>
      <div className={`input__wrapper ${wrapperClasses}`}>
        <textarea {...field} name={name} placeholder={placeholder} />
      </div>
      {meta.error && meta.touched ? (
        <small className="text-danger input__error d-block align-self-start">
          {meta.error}
        </small>
      ) : null}
    </>
  );
}

export default TextArea;
