import React from "react";
import "./Select.scss";
import { useField } from "formik";

/**
 * Renders a select input component with optional label, error handling, and default select option.
 *
 * @param {Object} props - The properties for the Input component.
 * @param {string} props.label - The label text for the input field.
 * @param {string} props.id - The id of the input field.
 * @param {function} props.onChange - The callback function for when the input value changes.
 * @param {string} props.name - The name of the input field.
 * @param {string} props.wrapperClasses - Additional CSS classes for the input wrapper.
 * @param {Array} props.options - An array of options for the select input.
 * @param {string} props.defaultSelect - The default select option text.
 * @return {JSX.Element} The rendered select input component.
 */
function Select({
  label,
  id,
  onChange,
  name,
  wrapperClasses,
  options,
  defaultSelect,
  disable = false,
  multiple = false,
}) {
  const [field, meta] = useField(name);
  return (
    <>
      <div className={`Select__wrapper ${wrapperClasses}`}>
        {label ? <label htmlFor={id}>{label}</label> : null}

        <select
          {...field}
          id={id}
          name={name}
          onChange={onChange ? onChange : field.onChange}
          disabled={disable}
          multiple={multiple}
        >
          {defaultSelect ? <option value="">{defaultSelect}</option> : null}
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.name}
            </option>
          ))}
        </select>

        {meta.error && meta.touched ? (
          <small className="text-danger input__error d-block mt-1">
            {meta.error}
          </small>
        ) : null}
      </div>
    </>
  );
}

export default Select;
