import React from "react";
import "./Footer.scss";
import { dashboardTitleImage } from "../../constants/asset";
function Footer() {
  return (
    <footer className="mt-5">
      <div className="footer__content">
        <img
          src={dashboardTitleImage}
          alt="dashboardTitleImage"
          srcSet={dashboardTitleImage}
          className="d-block mx-auto"
        />
        <p>Copyright &copy; 2024 | Thurstan College Media Unit</p>
        <p>
          For any inquiries: <br />inquire@tcmuofficial.com <br />+94769320733 |
          +94740124700
        </p>
      </div>
    </footer>
  );
}

export default Footer;
