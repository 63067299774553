import React, { useEffect } from "react";  
import { preLoaderAnim } from "../../animations";
import "./preloaderpb.css";
import Logo_Anim from '../../assets/images/PB-Logo-Anim.gif';

const PreloaderPB = () => {

    useEffect(() => {
        preLoaderAnim()
    }, []);

    return (
        <div className="preloaderpb">

        <img src={Logo_Anim} alt="My Image" />
        
        </div>
    )
}

export default PreloaderPB
