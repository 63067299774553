import React from "react";
import "./CommingSoon.scss";
import "animate.css";
import Header from "../../components/Header";

function CommingSoon() {
  
  return (
    <div className="container-fluid m-0 p-0 CommingSoon__wrapper">
      <Header />
      <div className="CommingSoon__wrapper__hero">
        <h1 className="CommingSoon__wrapper__hero__main__heading animate__animated animate__fadeInDown">
          Media Unit <br />
          <span>of Thurstan College</span>
        </h1>
        <h1 className="CommingSoon__wrapper__hero__sub__heading animate__animated animate__fadeInDown">
          Coming Soon
          <br />
          <span>The digitalized new era of school media is coming soon.</span>
        </h1>
      </div>
    </div>
  );
}

export default CommingSoon;

