import "./TshirtFormSubmitted.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { prabashwaraLogo } from "../../constants/asset";


function TshirtFormSubmitted() {

  return (

    <div className="container-fluid p-0 m-0 Registrations__wrapper">
      <Header theme="dark" />
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              src={prabashwaraLogo}
              alt="prabashwaraLogo"
              className="Registrations__logo d-block mx-auto"
            />
            <div className="Registrations__form__wrapper p-5 mt-5 w-100">
              <h2 className="Registrations__form__title text-center">
                Order placed.
              </h2>
              <h5>Thank you for your order. We will inform you when order is ready.</h5>
              <p>
                For any inquiries drop a WhatsApp massage:<br />
                Pahan: <a href="https://wa.me/+94740124700" target="_blank">+94 74 012 4700</a><br />
                Tharusha: <a href="https://wa.me/+94769320733" target="_blank">+94 76 932 0733</a>
                <br /><br />
                #WEAREDIFFERENT
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    
  );
}

export default TshirtFormSubmitted;
