import {
  collection,
  getDocs,
  orderBy,
  query,
  limit,
  getDoc,
  doc,
  where,
} from "firebase/firestore";
import { db } from "../config/firebase";

/**
 * Retrieves the last saved record from a Firestore collection.
 *
 * @param {string} collectionName - The name of the collection to query.
 * @return {Promise<Object>} - A promise that resolves to the last saved document,
 * with the document ID and data.
 * @throws {Error} - If there's an error retrieving the document.
 */
const getLastSavedRecord = async (collectionName) => {
  try {
    // Construct a Firestore query to retrieve the last saved document in the collection.
    const q = query(
      collection(db, collectionName), // The collection to query.
      orderBy("timestamp", "desc"), // Sort documents by timestamp, descending.
      limit(1) // Limit the query to retrieve only the last document.
    );

    // Execute the query and retrieve the document.
    const querySnapshot = await getDocs(q);

    // Return the document ID and data as an object.
    return {
      _id: querySnapshot.docs?.[0]?.id, // Document ID.
      ...querySnapshot.docs?.[0]?.data(), // Document data.
    };
  } catch (error) {
    // If there's an error, rethrow it.
    throw error;
  }
};

const getAllRecords = async (collectionName, sort) => {
  try {
    const records = collection(db, collectionName);
    if (sort) {
      const q = query(records, orderBy("timestamp", sort));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => {
        return { _id: doc.id, ...doc.data() };
      });
    }
    const querySnapshot = await getDocs(records);
    return querySnapshot.docs.map((doc) => {
      return { _id: doc.id, ...doc.data() };
    });
  } catch (error) {
    throw error;
  }
};

/**
 * Retrieves a single record from a Firestore collection by its ID.
 *
 * @param {string} collectionName - The name of the collection to query.
 * @param {string} id - The ID of the document to retrieve.
 * @return {Promise<Object>} - A promise that resolves to the document with the specified ID,
 * with the document ID and data.
 * @throws {Error} - If there's an error retrieving the document.
 */
const getSingleRecord = async (collectionName, id) => {
  try {
    // Construct a Firestore reference to the document.
    const record = doc(db, collectionName, id);

    // Retrieve the document snapshot.
    const recordSnapshot = await getDoc(record);

    // Return the document ID and data as an object.
    return { _id: recordSnapshot.id, ...recordSnapshot.data() };
  } catch (error) {
    // If there's an error, rethrow it.
    throw error;
  }
};

const findByDocumentKey = async (collectionName, key, value) => {
  try {
    const records = collection(db, collectionName);
    const q = query(records, where(key, "==", value));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      return {
        _id: querySnapshot.docs?.[0]?.id,
        ...querySnapshot.docs?.[0]?.data(),
      };
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};
/**
 * Retrieves all documents from a Firestore collection that have a specific
 * field value.
 *
 * @param {string} collectionName - The name of the collection to query.
 * @param {string} key - The field to search for.
 * @param {string} value - The value of the field to search for.
 * @return {Promise<Array>} - A promise that resolves to an array of documents
 * that match the search criteria, or an empty array if no documents match.
 * @throws {Error} - If there's an error retrieving the documents.
 */
const findByDocumentsKey = async (collectionName, key, value) => {
  try {
    // Construct a Firestore query to retrieve all documents in the collection
    // that match the specified field value.
    const records = collection(db, collectionName);
    const q = query(records, where(key, "==", value));
    const querySnapshot = await getDocs(q);

    // If any documents match the search criteria, map them to an array of
    // objects containing the document ID and data.
    if (querySnapshot.docs.length > 0) {
      return querySnapshot.docs.map((doc) => {
        return { _id: doc.id, ...doc.data() };
      });
    } else {
      // If no documents match the search criteria, return an empty array.
      return [];
    }
  } catch (error) {
    // If there's an error retrieving the documents, rethrow the error.
    throw error;
  }
};

export {
  getLastSavedRecord,
  getAllRecords,
  getSingleRecord,
  findByDocumentKey,
  findByDocumentsKey,
};
